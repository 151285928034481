@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebRegular";
    src: url("../../font/PT/PT-Root-UI_Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

:root {
    font-family: "PTRootUIWebRegular", sans-serif;
}

.form-content-right {
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;
    height: 100vh;
    position: relative;
    width: 100%;
}

.right-container {
    position: relative;
    height: 612px;
    padding-top: 10%;
    width: 100%;
}

.close-btn {
    position: absolute;
    top: 4%;
    right: 1.5%;
    font-size: 3rem;
    z-index: 1;
    color: grey;
    cursor: pointer;
    font-family: "Times New Roman", Times, serif;
}

.form-content-left {
    background-image: url("../../img/background-signin.jpg");
    /* background-image: linear-gradient(90deg, #3d5a80, white); */
    background-repeat: no-repeat;
    position: fixed;
    background-size: cover;
    height: 100%;
    width: 66.67%;
}

.form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
}

.form-signin {
    position: relative;
    width: 90%;
    height: auto;
    display: block;
    padding-bottom: 10%;
    margin: auto;
}

.form-signin h1 {
    font-family: "PTRootUIWebBold", sans-serif;
    font-size: 2rem;
    text-align: start;
    width: 80%;
    color: black;
    margin-bottom: 45px;
    letter-spacing: 1px;
}

.form-inputs {
    margin-bottom: 1rem;
    width: 100%;
    height: 72px;
}

.form-inputs span {
    font-size: 0.75rem;
    color: #f87700;
    margin: 0;
}

.form-label {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 6px;
    color: black;
    font-family: "PTRootUIWebMedium", sans-serif;
}

.form-input {
    display: block;
    border-radius: 2px;
    height: 40px;
    width: 98%;
    box-shadow: inset 0px 0px 0px 1px lightgrey;
    border: none;
    padding: 0;
    padding-left: 2%;
}

.form-input::placeholder {
    color: lightgrey;
    font-size: 1rem;
    font-family: "PTRootUIWebRegular", sans-serif;
}

.form-input-btn2:hover {
    cursor: pointer;
    background: #98c1d9;
    transition: all 0.4s ease-out;
}

.form-input-login {
    font-size: 1rem;
    margin-top: 10px;
    color: black;
    text-align: center;
}

.form-input-login a {
    text-decoration: none;
    color: #3d5a80;
    font-weight: 600;
}

.turn-to-login {
    margin-top: 10px;
}

.MuiTypography-body1 {
    font-family: "PTRootUIWebMedium", sans-serif;
}

.divider-container {
    width: 100%;
}

.form-input-btn2 {
    width: 180px;
    height: 42px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-radius: 2px;
    background: #3d5a80;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    font-family: "PTRootUIWebBold", sans-serif;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
}


/* breakpoint */


/* @media screen and (max-width: 1025px) {
    .close-btn {
        right: 4%;
    }
    .2 {
        width: 80%;
    }
} */

@media screen and (max-width: 959px) {
    .form-content-left {
        width: 33.3%;
        margin: 0;
    }
    .form-input-btn2 {
        width: 100%;
    }
    .form-input-login {
        padding-bottom: 40px;
    }
    .close-btn {
        right: 3%;
    }
    .form-signin {
        width: 90%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 599px) {
    .form-content-left {
        display: none;
    }
    .close-btn {
        right: 4%;
    }
}