@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

.prediction-title-container h1 {
    color: #3d5a80;
    font-size: 2.5rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    margin: 36px 0;
    text-align: left;
}


/* span {
    color: #f87700;
} */

button {
    cursor: pointer;
}

.prediction-file {
    padding: 20px 30px 30px 30px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-top: 45px;
}

.prediction-content h1 {
    color: #3d5a80;
    font-size: 1.5rem;
    font-family: "PTRootUIWebBold", sans-serif;
    letter-spacing: 0;
    text-align: left;
}

.button-container {
    text-align: left;
    color: #98c1d9;
    margin-top: 8px;
}

.button-container button {
    color: #98c1d9;
    padding: 0;
    font-size: 1rem;
}

.upload-container {
    text-align: left;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.upload-container button {
    text-align: left;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    display: flex;
}

.upload-container button p {
    color: #3d5a80;
    font-family: PTRootUIWebBold;
    text-decoration: underline;
    line-height: 2rem;
    font-size: 1rem;
    max-width: none;
    margin: 0;
}

#uploadIcon {
    margin-right: 12px;
    margin-top: 6px;
}

.upload-container p {
    text-align: left;
    padding: 0;
    color: grey;
    font-size: 1rem;
    margin: 0;
    line-height: 2rem;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.prediction-left {
    width: 45%;
}

.prediction-sequence {
    padding: 20px 30px 30px 30px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-top: 20px;
}

.sequence-input {
    font-family: "PTRootUIWebRegular", sans-serif;
}

.prediction-sequence textarea {
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-top: 20px;
}

.prediction-right {
    width: 45%;
    text-align: right;
    margin-top: 45px;
}

.prediction-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.MuiMobileStepper-dot {
    width: 4px;
    height: 4px;
}

.MuiPaper-root {
    padding: 0;
}

.MuiPaper-root p {
    color: #293241;
}

.MuiPaper-root h2 {
    color: #3d5a80;
}

.MuiPaper-root {
    border-radius: 2px;
    padding: 10px 40px;
}

.MuiDialogContent-root {
    border-bottom: none;
}

.task-card {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    text-align: left;
    padding: 20px;
    margin-top: 30px;
}

.time-container {
    display: flex;
    justify-content: space-between;
}

.model-status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.result-button-container {
    text-align: right;
}

.resultButton {
    min-width: 150px;
    height: 42px;
    border-radius: 2px;
    background: #3d5a80;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
}

.model-status-container h3 {
    color: #3d5a80;
    font-size: 2rem;
}

.model-status-container p {
    color: #3d5a80;
    font-family: "PTRootUIWebBold", sans-serif;
}

.time-container p {
    color: grey;
    font-size: 0.85rem;
}

.id-file-coantiner p {
    color: grey;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 0.85rem;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.id-file-coantiner {
    margin-top: 15px;
}

.id-file-coantiner span {
    color: grey;
    font-family: "PTRootUIWebBold", sans-serif;
}

.id-file-coantiner a {
    color: #98c1d9;
    font-family: "PTRootUIWebBold", sans-serif;
    text-decoration: underline;
}

.time-container span {
    font-family: "PTRootUIWebBold", sans-serif;
    color: grey;
}

.status-container {
    display: flex;
    margin-top: 10px;
}

.status-container img {
    width: 21px;
    height: 21px;
    margin-right: 10px;
    margin-top: 2px;
}

.task-information-container {
    text-align: left;
    padding: 30px 0;
    margin-bottom: 30px;
}

.id-file-result-container {
    display: flex;
    justify-content: space-between;
}

.preview-input {
    width: 100%;
    height: 28px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px lightgrey;
    border: none;
    padding-left: 6px;
    margin-top: 6px;
}

label {
    font-family: "PTRootUIWebBold", sans-serif;
    color: #293241;
}

.preview-cell {
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
}

.preview-cell p {
    font-family: "PTRootUIWebRegular", sans-serif;
    color: #293241;
    font-size: 0.85rem;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.preview-buttons {
    float: right;
    display: flex;
}

.preview-input::placeholder {
    color: lightgrey;
}

.task-content {
    text-align: left;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgrey;
}

.task-bottom-noti {
    text-align: left;
    color: grey;
    margin-top: 30px;
    font-size: 0.85rem;
}

.task-bottom-noti span {
    font-family: "PTRootUIWebBold", sans-serif;
}

.result-container {
    width: 100%;
}

.result-logo-container {
    margin-right: 40px;
}

.task-center {
    margin-bottom: 36px;
}

.pie-chart-container {
    display: flex;
    border-left: lightgrey solid 1px;
    border-bottom: lightgrey solid 1px;
    margin-bottom: 30px;
}

.pie-chart-cell-container {
    width: 50%;
    border-right: lightgrey solid 1px;
}

.pie-chart-cell {
    width: 100%;
    /* padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%; */
    padding: 2% 0;
}

.pie-chart {
    display: flex;
    justify-content: space-between;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
}


/* .arg-legend {
    margin-left: 30px;
} */

.prediction-legend {
    margin-left: 10px;
}

.prediction-legend g {
    max-width: 20px;
}

.prediction-legend-container {
    max-height: 160px;
    overflow-y: auto;
    overflow-x: auto;
}

.pie-chart-header {
    background-color: #f5f5f5;
    width: 100%;
}

.pie-chart-header p {
    width: 100%;
    text-align: center;
    font-family: "PTRootUIWebBold", sans-serif;
    padding: 6px 0;
    border-right: lightgrey solid 1px;
    border-bottom: lightgrey solid 1px;
    border-top: 1px solid lightgrey;
    margin: 0;
    color: #3d5a80;
}

.table-container {
    border-top: lightgrey 1px solid;
    border-left: lightgrey 1px solid;
    border-right: lightgrey 1px solid;
    margin-bottom: 40px;
}

.table-header {
    display: flex;
    width: 100%;
}

.list-item {
    display: flex;
    padding: 6px 10px;
    border-bottom: lightgrey 1px solid;
}

#filter-list-item {
    border: 0;
}

.list-item button {
    padding: 0;
}

.text-button {
    font-size: 0.875rem;
    font-family: "PTRootUIWebMedium", sans-serif;
    margin-left: 10px;
    padding: 0;
    width: 80%;
    text-align: left;
}

.header-menu-container {
    display: flex;
    width: 25%;
    justify-content: center;
    border-right: lightgrey 1px solid;
    border-bottom: lightgrey 1px solid;
    background-color: #f5f5f5;
}

.header-menu-container p {
    text-align: center;
    font-family: "PTRootUIWebBold", sans-serif;
    padding: 6px 0;
    margin: 0;
    color: #3d5a80;
    width: 90%;
}

.header-menu-list-container {
    border-radius: 2px;
    margin-bottom: 2px;
    z-index: 10000;
    background-color: white;
}

.filter-container {
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-bottom: 4px;
    transition: all 0.3s ease-out;
}

.header-menu-list {
    padding: 0 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    margin-bottom: 2px;
    transition: all 0.3s ease-out;
}

.filter-button {
    width: 48px;
    height: 22px;
    border-radius: 2px;
    background-color: #3d5a80;
    color: white;
    margin: 10px 0;
}

.filter-input {
    border-radius: 2px;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    margin-top: 10px;
}

::placeholder {
    color: lightgrey;
}

.filter-form {
    padding: 0 8px;
    text-align: right;
}

.task-center-container {
    margin-bottom: 40px;
}

#task-card-interrupt {
    margin-right: 20px;
}

@media screen and (max-width: 959px) {
    .prediction-content {
        display: block;
    }
    .prediction-left {
        width: 100%;
    }
    .prediction-right {
        width: 100%;
    }
    .prediction-right-content {
        margin: auto;
        float: none;
    }
    .prediction-right {
        margin-top: 20px;
    }
    .prediction-file {
        margin-top: 20px;
    }
    .result-button-container {
        margin-top: 30px;
    }
    .id-file-coantiner p {
        max-width: 200px;
    }
    .pie-chart-container {
        display: block;
    }
    .pie-chart-cell-container {
        width: 100%;
    }
    .pie-chart-header {
        display: block;
    }
    .pie-chart-cell-container {
        display: block;
    }
    .pie-chart-cell {
        width: 100%;
    }
}

@media screen and (max-width: 599px) {
    .result-container {
        width: 90%;
        margin: auto;
    }
    .result-logo-container {
        margin-right: 0;
    }
    .prediction-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .prediction-container h1 {
        font-size: 1.8rem;
        margin-top: 10px;
    }
    .process-task-content {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .model-status-container h3 {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .task-center {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .id-file-coantiner p {
        max-width: 150px;
    }
    .task-center-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .result-button-container {
        justify-content: space-between;
    }
}