@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebRegular";
    src: url("../../font/PT/PT-Root-UI_Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

:root {
    font-family: "PTRootUIWebRegular", sans-serif;
}

.form-container1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    align-items: center;
}

.form-inputs1 {
    width: 100%;
    height: 72px;
    margin-bottom: 1rem;
}

.close-btn1 {
    position: absolute;
    top: 3%;
    right: 3%;
    font-size: 3rem;
    z-index: 1;
    color: grey;
    cursor: pointer;
    font-family: "Times New Roman", Times, serif;
}

.form-content-left1 {
    background-image: url("../../img/background-signup.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    width: 33.3%;
    height: 100%;
}

.form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
}

.form-content-right1 {
    height: 100%;
    width: 100%;
}

.form {
    height: auto;
    margin-left: 4.5%;
    padding-top: 6.7%;
    padding-bottom: 6.7%;
}

.form h1 {
    font-family: "PTRootUIWebBold", sans-serif;
    font-size: 2rem;
    text-align: start;
    width: 80%;
    color: black;
    margin-bottom: 45px;
    letter-spacing: 1px;
}

.form-inputs1 span {
    font-size: 0.75rem;
    color: #f87700;
}

.form-label {
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 6px;
    color: black;
    font-family: "PTRootUIWebMedium", sans-serif;
}

.form-input1 {
    background-color: transparent;
    display: block;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 90%;
    padding-left: 2%;
    box-shadow: inset 0px 0px 0px 1px lightgrey;
    border: none;
}

.form-input1::placeholder {
    color: lightgrey;
    font-size: 16px;
    font-family: "PTRootUIWebRegular", sans-serif;
}

.form-input-btn1 {
    width: 180px;
    height: 42px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-radius: 2px;
    background: #3d5a80;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    font-family: "PTRootUIWebBold", sans-serif;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
}

.form-input-btn1:hover {
    cursor: pointer;
    background: #98c1d9;
    transition: all 0.4s ease-out;
}

.form-input-login {
    font-size: 1rem;
    margin-top: 10px;
    color: black;
    text-align: center;
}

.form-input-login a {
    text-decoration: none;
    color: #3d5a80;
    font-weight: 600;
}

.turn-to-login {
    margin-top: 10px;
}

.turn-to-forget-password {
    margin-top: 10px;
}

.form-location {
    width: 90%;
    margin-bottom: 20px;
}


/* breakpoint */


/* @media screen and (max-width: 1023px) {
    .form-location {
        width: 80%;
    }
    .form-input-login {
        padding-bottom: 40px;
    }
} */


/* 
@media screen and (max-width: 767px) {
    .form-content-left1 {
        display: none;
    }
    .form-container1 {
        width: 100%;
        position: relative;
        margin-left: -15%;
        height: 600px;
    }
} */

@media screen and (max-width: 959px) {
    .form-input-btn1 {
        width: 90%;
        height: 42px;
        margin-top: 10px;
        border-radius: 2px;
        background: #3d5a80;
        outline: none;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        font-family: "PTRootUIWebBold", sans-serif;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    }
    .form-inputs1 {
        width: 90%;
    }
    .form-location {
        width: 90%;
    }
    .close-btn1 {
        right: 5%;
    }
    .form-input1 {
        width: 100%;
    }
    .form {
        margin-left: 9%;
    }
}

@media screen and (max-width: 599px) {
    .form-content-left1 {
        display: none;
    }
    .form-content-right1 {
        width: 100%;
        position: relative;
        /* margin-left: 30px; */
    }
    .form-input-btn1 {
        width: 90%;
        height: 42px;
        margin-top: 10px;
        border-radius: 2px;
        background: #3d5a80;
        outline: none;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        font-family: "PTRootUIWebBold", sans-serif;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    }
    .form-location {
        width: 90%;
    }
    .form-input-login {
        padding-bottom: 40px;
    }
    .close-btn1 {
        right: 8%;
        top: 2%;
    }
}