@font-face {
    font-family: "PTRootUIWebBold";
    src: url("../font/PT/PT-Root-UI_Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

@font-face {
    font-family: "PTRootUIWebMedium";
    src: url("../font/PT/PT-Root-UI_Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    unicode-range: U+000-5FF;
}

.entries-title p {
    color: grey;
    font-size: 0.875rem;
    line-height: 1.2rem;
    margin-top: 20px;

}

.LandingContent {
    display: block;
    float: center;
    margin: 60px auto 60px auto;
}

.login-prompt {
    padding-top: 40px;
    width: 66.7%;
    margin-left: auto;
    margin-right: auto;
}

.login-prompt p {
    color: grey;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-top: 20px;
    font-family:"PTRootUIWebMedium";
    
}

.entries-title a {
    font-size: 1rem;
}

.entries-title a:hover {
    color: #f87700;
    transition: all 0.4s ease-out;
}

.entries-container {
    margin-top: 40px;
    width: 66.7%;
    margin-left: auto;
    margin-right: auto;
}

.entries-content {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.entry {
    width: 150px;
    margin: 20px 0;
}

.entry img {
    width: 100px;
    margin-bottom: 20px;
}

.entry p {
line-height: 1.2;
    font-size: 1rem;
    color: gray;
    text-align: center;
    font-family: "PTRootUIWebMedium";
}

.SupportLogos {
    height: 65px;
    text-align: left;
}

.MuiTypography-displayBlock-268 {
    color: #3d5a80;
}

.landing-login-model {
    background-color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.version-button {
    width: 180px;
    border: 2px white solid;
    font-size: 24px;
    color: white;
    /* background-color:white; */
    padding: 8px 0;
    margin: auto;
    border-radius: 2px;
    margin: 40px 40px;
    cursor:default
}

@media screen and (max-width: 959px) {
    .featureTitleText {
        width: 50%;
    }
    .featurePage {
        height: auto;
        background-size: 160%;
        padding: 0;
    }
    .featureText {
        width: 100%;
        position: relative;
        margin-top: 40px;
    }
    .feature1 img {
        width: 100%;
        float: none;
        margin: auto;
        top: 1%;
        position: relative;
    }
    .feature1 {
        height: 500px;
        margin-bottom: 15%;
    }
    .feature2 {
        height: 500px;
        margin-top: 0;
        margin-bottom: 15%;
    }
    .feature2 img {
        width: 100%;
        float: none;
        margin: auto;
        top: 1%;
        position: relative;
    }
}

@media screen and (max-width: 599px) {
    .featureTitleText {
        width: 90%;
    }
    .featurePage {
        width: 90%;
        margin: auto;
        background-size: 200%;
    }
    .feature1 {
        margin-bottom: 0;
    }
    .feature2 {
        margin-bottom: 5%;
    }
    .entries-container {
        width: 90%;
        margin-top: 20px;
    }
    .login-prompt {
        width: 90%;
        padding-top: 20px;
    }
}