.profile-content-left {
    display: block;
    width: 40%;
    margin-right: 10%;
}

.profile-content-both {
    display: flex;
    width: 100%;
    margin: 0;
}

.profile-content-right {
    display: block;
    width: 40%;
}

#customized-menu {
    margin-top: 15px;
    padding: 0;
    margin-left: 4px;
}

@media screen and (max-width: 599px) {
    .profile-content-left {
        display: block;
        width: 45%;
        margin-right: 10%;
    }
    .profile-content-right {
        display: block;
        width: 45%;
    }
    .profile-dropdown {
        width: 223%;
    }
    .profile-content {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}